
import { computed, defineComponent, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import { IVendorSearchMapping } from '@/types/interfaces/IVendorSearchMapping'
// import { RuleObject } from 'ant-design-vue/lib/form/interface'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { CalculatorOutlined } from '@ant-design/icons-vue'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { ShippingDocumentTypes } from '@/types/enums/ShippingDocumentTypes'
// import { IShippingRateCard } from '@/types/interfaces/IShippingRateCard'
import { ConsignmentFormData } from '@/types/appcontracts/ConsignmentFormData'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { IShipmentDimensions } from '@/types/interfaces/IShipmentDimensions'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import ModalSlot from '@/components/General/ModalSlot.vue'
import { fixDecimalValue, formatAddressObject } from '@/services/helpers'
import { IVendorServiceSearchMapping } from '@/types/interfaces/IVendorServiceSearchMapping'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { AppConfigSettingsDocument } from '@/types/firebaseCollectionContracts/AppConfigSettingsDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { ShippingRatesDocument } from '@/types/firebaseCollectionContracts/ShippingRatesDocument'
import { VendorServicesDocument } from '@/types/firebaseCollectionContracts/VendorServicesDocument'
import dayjs from 'dayjs'
import { dateFormats } from '@/services/helpers/constants'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useFetch } from '@vueuse/core'

const initialOriginAddress: IAddressFormat = {
  country: 'India',
  attention: null,
  city: null,
  state: null,
  address: null,
  pincode: null,
}

const initialDestAddress: IAddressFormat = {
  country: null,
  attention: null,
  city: null,
  state: null,
  address: null,
  pincode: null,
}

const initialDimensions: IShipmentDimensions = { l: 1, b: 1, h: 1 }

const getInitialState = () => {
  const initialFormState: ConsignmentFormData = {
    awb: null,
    rfn: null,
    docType: ShippingDocumentTypes.NDox,
    vendorId: null,
    serviceId: null,
    carrierId: null,
    carrierServiceId: null,
    customerId: null,
    originAddress: { ...initialOriginAddress },
    destinationAddress: { ...initialDestAddress },
    zoneId: null,
    weight: null,
    dimensions: { ...initialDimensions },
    pcs: 1,
    amount: 0,
    paymentMode: null,
    shipmentStatus: null,
    statusMessage: null,
    bookingDate: null,
    shipmentValue: 0,
    vchc: 0,
    deliveryDate: null,
    receivedBy: null,
    remarks: null,
    invoiceId: null,
    senderName: null,
    senderPhone: null,
  }

  return initialFormState
}

export default defineComponent({
  name: 'ConsignmentEntry',
  components: { InputFormAddress, ModalSlot, CalculatorOutlined },
  setup() {
    const store = useStore()
    const route = useRoute()
    const editAwb = ref((route.params.id as string) || null)
    const ratesIdMap = computed(
      () =>
        store.state.rates.shippingRatesIdMap as Map<string, IAppDocument<ShippingRatesDocument>>,
    )
    const vendorServicesIdMap = computed(
      () =>
        store.state.vendorServices.vendorServicesIdMap as Map<
          string,
          IAppDocument<VendorServicesDocument>
        >,
    )
    const currentServiceDoc = computed(
      () => vendorServicesIdMap.value.get(formState.serviceId || '')?.doc || null,
    )
    const currentRateDoc = ref<ShippingRatesDocument | null>(null)
    const currentZoneRate = computed(() => {
      // console.log('currentZoneRate ZONEID: ', formState.zoneId)
      return currentRateDoc.value?.zoneRates?.find(
        (x) => x.zoneId === formState.zoneId && x.documentType === formState.docType,
      )
    })
    const currVolWeight = computed(() => {
      const dim = formState.dimensions
      return fixDecimalValue(((dim.l || 0) * (dim.b || 0) * (dim.h || 0)) / 5000)
    })
    const computedZoneId = computed(() => {
      return (
        currentServiceDoc.value?.zoneList?.find((x) =>
          x.areas.some((z) => z === formState.destinationAddress.pincode),
        )?.zid || null
      )
    })
    const computedAmount = computed(() => {
      return calcAmountFromRateCard()
    })
    const formRef = ref()
    const fetchedConsignmentData = ref<ConsignmentsDocument | null>(null)
    const fetchedAwb = ref<string | null>(null)
    const fetchedRfn = ref<string | null>(null)
    const fetchedAmount = ref<number | null>(null)
    const isOriginModalVisible = ref(false)
    const isDestinationModalVisible = ref(false)
    const isRfnConsignmentsModalVisible = ref(false)
    const isPaymentReceived = ref(false)
    const isCarrierChecked = ref(true)
    const isFetchConsignment = ref(false)
    const tagText = reactive({ text: 'New Shipment', color: 'processing' })
    const rfnConsignmentList = ref<ConsignmentsDocument[] | null>(null)
    const { getCustomerById } = useSearchMapping()

    const invIdArr = computed(() => {
      const inv = formState.invoiceId
      if (inv) {
        const ids = inv.split('_')
        return ids
      }
      return null
    })
    const shipmentStatusOptions = computed(() =>
      (store.state.appGlobals
        .appConfigSettings as AppConfigSettingsDocument)?.shipmentStatusList?.map((x) => {
        return {
          value: x.code,
          label: x.value,
        }
      }),
    )
    const paymentModeOptions = computed(() =>
      (store.state.appGlobals.appConfigSettings as AppConfigSettingsDocument)?.paymentModes?.map(
        (x) => {
          return {
            value: x,
            label: x,
          }
        },
      ),
    )
    const { zonesSearchList, serviceList } = useSearchMapping()
    const formState = reactive<ConsignmentFormData>(getInitialState())
    const loading = ref(false)
    const serviceToZoneOptions = computed<Array<any>>(() => {
      return serviceList.value.map((vs: any) => {
        return {
          label: vs.name,
          vendorId: vs.vendorId,
          options: [
            ...zonesSearchList.value
              .filter((z) => z.serviceId === vs.id)
              .map((el) => {
                return {
                  label: el.name,
                  value: el.id,
                  key: el.id,
                }
              }),
          ],
        }
      })
    })

    const filteredServiceToZoneOptions = computed(() => {
      return serviceToZoneOptions.value.filter((x) => x.vendorId === formState.vendorId) || []
    })

    const vendorsOptions = computed(() =>
      store.state.vendors.vendorsSearchList.map((v: IVendorSearchMapping) => {
        return {
          value: v.id,
          label: v.displayName,
        }
      }),
    )
    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })

    const vendorServiceList = computed(
      () => store.state.vendorServices.vendorServicesSearchList as IVendorServiceSearchMapping[],
    )
    const filteredServiceList = computed(() =>
      vendorServiceList?.value
        ?.filter((x) => x.vendorId === formState.vendorId)
        .map((el) => {
          return {
            value: el.id,
            label: el.name,
          }
        }),
    )

    const filteredCarrierServiceList = computed(() =>
      vendorServiceList?.value
        ?.filter((x) => x.vendorId === formState.carrierId)
        .map((el) => {
          return {
            value: el.id,
            label: el.name,
          }
        }),
    )

    const rules = computed(() => {
      return {
        awb: [{ required: true, message: 'Please enter AWB', trigger: 'blur', type: 'string' }],
        docType: [{ required: true, message: 'Please select document type', trigger: 'blur' }],
        customerId: [
          {
            required: true,
            message: 'Please select a Customer',
            trigger: 'change',
            type: 'string',
          },
        ],
        vendorId: [
          { required: true, message: 'Please select a Vendor', trigger: 'change', type: 'string' },
        ],
        serviceId: [
          { required: true, message: 'Please select Service', trigger: 'change', type: 'string' },
        ],
        carrierId: [
          { required: true, message: 'Please select a Carrier', trigger: 'change', type: 'string' },
        ],
        carrierServiceId: [
          {
            required: true,
            message: 'Please select Carrier Service',
            trigger: 'change',
            type: 'string',
          },
        ],
        weight: [
          { required: true, message: 'Please enter weight', trigger: 'blur', type: 'number' },
        ],
        amount: [
          { required: true, message: 'Please enter amount', trigger: 'blur', type: 'number' },
        ],
        paymentMode: [
          {
            required: isPaymentReceived.value,
            message: 'Please select Payment Mode',
            trigger: 'change',
            type: 'string',
          },
        ],
        bookingDate: [{ required: true, message: 'Please enter booking date', trigger: 'blur' }],
      }
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<ConsignmentFormData> = {
        action: DocumentActions.UPDATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `consignments/${actions.ConsignmentsAction.SET_OR_DELETE_CONSIGNMENT}`,
        docOperation,
      )
      loading.value = false
      if (isSuccess) {
        handleReset()
      }
    }

    // TODO: Remove if not needed.
    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleCarrierCheck = () => {
      if (isCarrierChecked.value) {
        formState.carrierId = formState.vendorId
        formState.carrierServiceId = formState.serviceId
      }
    }

    const fetchConsignmentData = async () => {
      isFetchConsignment.value = true
      formState.awb = formState.awb?.toUpperCase() || null
      // rfnConsignmentList.value = null
      // Proceed only if awb is not null and is not already fetched.
      if (formState.awb && fetchedAwb.value != formState.awb) {
        fetchedAwb.value = formState.awb
        fetchedRfn.value = null // As it has been fetched by awb
        rfnConsignmentList.value = null
        const resp: ConsignmentsDocument | null = await store.dispatch(
          `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID}`,
          {
            awb: fetchedAwb.value,
          },
        )
        fetchedConsignmentData.value = resp
        handleFetchedConsignmentData(resp)
      }
      isFetchConsignment.value = false
    }

    const fetchConsignmentDataByRfn = async () => {
      // TODO: This shall change as its not much usefull as of now. Or add it to state then it makes sense.
      // But for rfn data we cannot rely on state. We need to always fetch. :(
      isFetchConsignment.value = true
      formState.rfn = formState.rfn?.toUpperCase() || null
      // Proceed either if awb is empty or if present, then last fetch was from rfn (detect via consignmentList)
      if (
        (!formState.awb || rfnConsignmentList.value) &&
        formState.rfn &&
        fetchedRfn.value != formState.rfn
      ) {
        fetchedRfn.value = formState.rfn
        const resp: ConsignmentsDocument[] | null = await store.dispatch(
          `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID}`,
          {
            rfn: fetchedRfn.value,
          },
        )
        rfnConsignmentList.value = !resp || resp?.length === 0 ? null : resp
        fetchedConsignmentData.value = resp?.[0] || null
        // fetchedAwb.value = resp?.[0]?.awb || null // As it has been fetched by rfn, so we set fetchAwb so that its not fetched again.
        handleFetchedConsignmentData(resp?.[0] || null)
      }
      isFetchConsignment.value = false
    }

    const handleReset = (setAwb: string | null = null, setRfn: string | null = null) => {
      Object.assign(formState, getInitialState())
      formState.awb = setAwb || null
      formState.rfn = setRfn || null
      fetchedAwb.value = setAwb || null
      fetchedRfn.value = setRfn || null
      tagText.color = 'processing'
      tagText.text = 'New Shipment'
      isCarrierChecked.value = true
      isPaymentReceived.value = false
      rfnConsignmentList.value = null
      fetchedConsignmentData.value = null
    }

    const handleFetchedConsignmentData = (fetchedData: ConsignmentsDocument | null) => {
      if (fetchedData) {
        fetchedAwb.value = fetchedData?.awb || null
        fetchedAmount.value = fetchedData?.amount || null
        const { audit, ...prevData } = fetchedData
        Object.assign(formState, prevData)
        formState.bookingDate = formState?.bookingDate
          ? dayjs(formState?.bookingDate).format(dateFormats.momentValueFormat)
          : null
        formState.deliveryDate = formState.deliveryDate
          ? dayjs(formState.deliveryDate).format(dateFormats.momentValueFormat)
          : null
        tagText.text = formState.invoiceId ? 'Shipment Locked' : 'Update Shipment'
        tagText.color = formState.invoiceId ? 'error' : 'warning'
        isPaymentReceived.value = formState?.paymentMode ? true : false
        console.log('AUD: ', audit)
      } else {
        // Corner Case: When moving from newShipment to newShipment, we dont want prev values as blank.
        // As user might have entered a awb by mistake but have already filled the whoel form.
        // TODO: Find a better way then relying upon tagText color to detect corner case.
        if (tagText.color != 'processing') {
          handleReset(fetchedAwb.value, fetchedRfn.value)
        }
      }
    }

    const handleConsignmentListSelect = (fetchedData: ConsignmentsDocument) => {
      handleFetchedConsignmentData(fetchedData)
      isRfnConsignmentsModalVisible.value = false
    }

    const calcAmountFromRateCard = () => {
      const rateCard = currentZoneRate.value?.rateCard?.sort((a, b) => a.minw - b.minw) // sort number in asc
      const additional = currentZoneRate.value?.additionalRate
      // console.log('CALC: ', rateCard)
      if (rateCard) {
        let wt = formState.weight || 0
        let totalCost = 0
        // Add cost from rateCard
        for (let i = 0; i < rateCard.length; i++) {
          const rate = rateCard[i]
          if (wt > rate.minw) {
            totalCost += rate.cost
            wt -= rate.maxw
          } else {
            break
          }
        }
        // Add cost from additional rate
        if (additional) {
          if (wt > 0 && wt < additional.weight) {
            totalCost += additional.cost
          } else {
            const multiplier = Math.ceil(wt / additional.weight) // Add check for wt > 0
            const addCost = multiplier * additional.cost
            totalCost += addCost
          }
        }
        return totalCost
      }
      return 0
    }

    const fetchRateCard = async (customerId: string | null) => {
      if (!customerId) {
        return null
      }
      if (ratesIdMap.value.has(customerId)) {
        return ratesIdMap.value.get(customerId)?.doc || null
      } else {
        const resp = await store.dispatch(`rates/${actions.RatesAction.FETCH_RATES_BY_ID}`, {
          docId: customerId,
        })
        return resp?.doc || null
      }
    }

    const handleVendorChange = () => {
      formState.serviceId = null
      if (isCarrierChecked.value) {
        formState.carrierId = formState.vendorId
        formState.carrierServiceId = formState.serviceId
      }
    }

    const handleServiceChange = () => {
      if (isCarrierChecked.value) {
        formState.carrierId = formState.vendorId
        formState.carrierServiceId = formState.serviceId
      }
    }

    const pinApiUrl = computed(
      () =>
        'https://apiv2.shiprocket.in/v1/external/open/postcode/details?' +
        new URLSearchParams({ postcode: formState.destinationAddress.pincode || '' }),
    )
    const { execute, data } = useFetch(pinApiUrl, { immediate: false })
      .get()
      .json()
    // var { execute } = usePincodeFetch(formState.destinationAddress.pincode || "").

    const handlePincodeBlur = async () => {
      isFetchConsignment.value = true
      if (!formState.destinationAddress.city) {
        await execute()
        formState.destinationAddress.city = data.value?.postcode_details?.city || null
      }
      isFetchConsignment.value = false
    }

    const handleSenderNameUpdate = (oldCustId: string | null, newCustId: string | null) => {
      const cust = getCustomerById(oldCustId, null)
      const oldNm = cust ? `${cust.displayName} (${cust.cid})` : null
      if (!formState.senderName || formState.senderName === oldNm) {
        const newCust = getCustomerById(newCustId, null)
        const newNm = newCust ? `${newCust.displayName} (${newCust.cid})` : null
        formState.senderName = newCustId === 'CX99999' ? null : newNm
      }
    }

    // If paymentReceived is unchecked then set paymentMode as null.
    watch(isPaymentReceived, (val) => {
      if (!val) {
        formState.paymentMode = null
      } else {
        // else select a default option.
        formState.paymentMode = 'Cash'
      }
    })

    // Set Rate Card on Customer selection
    watch(
      () => formState.customerId,
      async (val, oldCustId) => {
        currentRateDoc.value = await fetchRateCard(val)
        handleSenderNameUpdate(oldCustId, val)
        // console.log('W_CUST: ', currentRateDoc.value)
      },
    )

    // Set Zone. But what will happen when serviceDoc not present as we are not fetching it.
    watch(computedZoneId, (newVal) => {
      // If data is just fetched, do not update with computed value.
      // But reset the fetchedData so that next update is picked.
      if (formState.zoneId !== fetchedConsignmentData.value?.zoneId) {
        formState.zoneId = newVal
        fetchedConsignmentData.value = null
      }
    })

    // Set Weight if Volumetric weight is more.
    watch(currVolWeight, (newVolWt, oldVolWt) => {
      // If data is just fetched, do not update with computed value.
      // But reset the fetchedData so that next update is picked.
      if (
        formState.weight !== fetchedConsignmentData.value?.weight &&
        (!formState.weight || newVolWt > formState.weight || formState.weight === oldVolWt)
      ) {
        formState.weight = newVolWt
        fetchedConsignmentData.value = null
      }
    })

    // Set Amount.
    watch(computedAmount, (newVal) => {
      // If data is just fetched, do not update with computed value.
      // But reset the fetchedData so that next update is picked.
      if (
        formState.amount !== fetchedConsignmentData.value?.amount ||
        formState.weight !== fetchedConsignmentData.value.weight ||
        formState.zoneId !== fetchedConsignmentData.value.zoneId
      ) {
        formState.amount = newVal
        fetchedConsignmentData.value = null
      }
    })

    const handeRouteEditQuery = (val: string | null = null) => {
      // console.log()
      editAwb.value = val || editAwb.value
      if (editAwb.value) {
        formState.awb = editAwb.value
        fetchConsignmentData()
      }
    }

    onMounted(() => {
      handeRouteEditQuery()
    })

    onBeforeRouteUpdate(async (to) => {
      handeRouteEditQuery(to.params.id as string)
    })

    return {
      formRef,
      formState,
      rules,
      loading,
      handleFinish,
      handleFinishFailed,
      customersOptions,
      vendorsOptions,
      serviceToZoneOptions,
      filteredServiceToZoneOptions,
      isOriginModalVisible,
      isDestinationModalVisible,
      formatAddressObject,
      isPaymentReceived,
      handleCarrierCheck,
      isCarrierChecked,
      filteredServiceList,
      filteredCarrierServiceList,
      fetchConsignmentData,
      isFetchConsignment,
      tagText,
      fetchConsignmentDataByRfn,
      rfnConsignmentList,
      isRfnConsignmentsModalVisible,
      handleConsignmentListSelect,
      shipmentStatusOptions,
      paymentModeOptions,
      currVolWeight,
      invIdArr,
      editAwb,
      fetchedAmount,
      handleVendorChange,
      handleServiceChange,
      handlePincodeBlur,
    }
  },
})
